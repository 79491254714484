import React, { PureComponent } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';

import './contact.scss';

class Contact extends PureComponent {
  render() {
    return (
      <div className="container">
        <Header />
        <section className="d-flex flex-column justify-content-center contact-section-container">
          <p>
            For work related enquiries, email me &nbsp;
            <a target="_blank" rel="noopener noreferrer" href="mailto:shbmbhrdwj@gmail.com">
              shbmbhrdwj@gmail.com
            </a>
          </p>
          <p>
            Have any questions? You can &nbsp;
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/shbmbhrdwj/ama">
              ask me anything.
            </a>
          </p>
          <p>
            Want to connect with me? Find me on twitter &nbsp;
            <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/shbhmbhrdwj">
              @shbhmbhrdwj
            </a>
          </p>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Contact;
